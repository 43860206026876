import {format} from 'date-fns'
import React from 'react'
import {buildImageObj, getBookUrl, leadingZeros} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import '../styles/book-single.css'
import {Link} from 'gatsby'
import SimpleReactLightbox from "simple-react-lightbox"
import { SRLWrapper } from "simple-react-lightbox";

function BookSingle (props) {
  const {_rawDescription, spreads, coverSpread} = props
  //console.log(spreads)

  const options = {
    thumbnails: {
      showThumbnails: false
    },
    buttons: {
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false
    }
  };

  if(spreads.length > 1) {
    options.buttons.showNextButton = true
    options.buttons.showPrevButton = true
  }

  return (
    <Container>
    <SimpleReactLightbox>
      <div className="wrapper">
        <h3><Link to="/books/">Books</Link></h3>
        <div id="book-single" className="book-single">

            <h2>{props.title}</h2>

            <div className="grid">

              <div className="width-12-12-m width-6-12">

              {props.mainImage && props.mainImage.asset && (
                <div className="image-holder">

                  <Link to={getBookUrl(props.publishedAt, props.slug.current)}>
                  <figure
                  style={{
                    backgroundImage: `url(${props.mainImage.asset.metadata.lqip})`,
                    paddingTop: `calc(100% / ${props.mainImage.asset.metadata.dimensions.aspectRatio})`
                   }}
                >
                  <img
                    src={imageUrlFor(buildImageObj(props.mainImage))
                      .width(1200)
                      .auto('format')
                      .url()}
                    alt={props.mainImage.alt}
                    title={props.mainImage.alt}
                  />
                </figure>
                  </Link>
                  {props.mainImage.caption && (
                    <span className="caption">
                      {props.mainImage.caption}
                    </span>
                  )}

                </div>
              )}

              </div>

              <div className="width-12-12-m width-6-12">


                <div className="info">
                
                  <div>
                    <p>By:</p>

                    <p>Date:</p>

                    {props.publisher && props.link && (
                      <p>Publisher:</p>
                    )}

                    <p>Catalog:</p>
                  </div>
                  <div>

                    <p>{props.author}</p>

                    <p>{format(new Date(props.publishedAt), 'MM.DD.YYYY')}</p>

                    {props.publisher && props.link && (
                      <p><a aria-label={props.title} rel="noopener noreferrer" title={props.title} href={props.link} target="_blank">{props.publisher}</a></p>
                    )}

                    <p>{leadingZeros(props.catalogNumber)}</p>
                  </div>

                </div>

                {props._rawDescription && (
                  <div className="body-holder">
                    {_rawDescription && <PortableText blocks={_rawDescription} />}
                  </div>
                )}

                {spreads && coverSpread && coverSpread.asset && (
                  <h3>Typesetting</h3>
                )}

                <SRLWrapper options={options}>

                  <div className="grid">

                  {coverSpread && coverSpread.asset && (
                    
                    <div className="gallery-image width-6-12-m width-6-12">
                    <a data-attribute="SRL" href={imageUrlFor(buildImageObj(coverSpread))
                          .width(2000)
                          .url()}
                    >
                      <img
                        src={imageUrlFor(buildImageObj(coverSpread))
                          .width(1200)
                          .url()}
                        alt={coverSpread.alt}
                      />
                      </a>
                    </div>

                  )}

                  {spreads && spreads.map(image => {

                    return image.asset && (

                    <div className="gallery-image width-6-12-m width-6-12">
                    <a data-attribute="SRL" href={imageUrlFor(buildImageObj(image))
                          .width(2000)
                          .url()}
                    >
                      <img
                        src={imageUrlFor(buildImageObj(image))
                          .width(1200)
                          .url()}
                        alt={image.alt}
                      />
                      </a>
                    </div>
                  )
                  })}

                  </div>

              </SRLWrapper>

              {props.embed && 
                <div className="embed">
                <iframe src={props.embed} 
                        title="artwork embed"
                        width="100%" 
                        height="480"
                        seamless="seamless" 
                        scrolling="no" 
                        frameBorder="0" 
                        allowFullScreen>
                </iframe>
                </div>
              }

          </div>

          </div>

        </div>
      </div>
    </SimpleReactLightbox>
    </Container>
  )
}

export default BookSingle